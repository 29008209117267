import React from "react";
import { Button } from '@headlessui/react'

const Resume = () => {
  function handlePrint() {
    const printContents = document.getElementById("printable").innerHTML;
    const originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  }

  return (
    <div className="min-h-screen flex flex-col items-center justify-center transition-colors duration-300 bg-gray-900 px-4 overflow-hidden print:hidden">
      <style>
        {`
          @media print {
            @page {
              size: legal;
              margin: 0;
            }
            body {
              transform: scale(0.95);
              transform-origin: top left;
            }
          }
        `}
      </style>
      <div
        id="printable"
        className="w-[8.5in] h-[14in] bg-white rounded-md shadow-lg p-4 font-sans m-1 overflow-none">
        <div>
          {/* Header */}
          <div className="text-center mb-4">
            <h1 className="text-4xl font-bold text-gray-800">Rich McNinch</h1>
            <div className="text-gray-600 mt-1 text-sm">
              <p>
                801 E 15th St, Sterling, IL 61081 | (815) 535-6502 |
                richmcninch@gmail.com | richmcninch.com/resume{" "}
              </p>
            </div>
          </div>

          {/* Professional Summary */}
          <div className="mb-4 text-center text-gray-700">
            Versatile technology professional with over 25 years experience in
            IT infrastructure, web development, and business operations. Skilled
            in creating innovative technical solutions, managing complex
            systems, and delivering comprehensive support across diverse
            business environments.
          </div>

          {/* Main Content - Two Column Layout */}
          <div className="grid grid-cols-3 gap-4 items-start">
            {/* Experience Column */}
            <div className="col-span-2">
              <h2 className="text-2xl font-semibold border-b-2 border-gray-300 mb-4">
                Professional Experience
              </h2>

              {/* AEGON Experience */}
              <div className="mb-4">
                <div className="flex justify-between items-center">
                  <h3 className="font-bold text-lg">
                    Distributed Infrastructure Support Engineer
                  </h3>
                  <p className="text-gray-600 text-sm">Sep 2023 - Dec 2024</p>
                </div>
                <p className="text-gray-700 font-semibold italic">
                  AEGON / TransAmerica, Cedar Rapids IA
                </p>
                <ul className="list-disc list-inside text-sm text-gray-700 space-y-1">
                  <li>
                    Provide overnight support for European staff
                    on a 12-hour shift
                  </li>
                  <li>
                    Resolve server issues using ServiceNow and BigPanda
                    monitoring platforms
                  </li>
                  <li>
                    Manage infrastructure across multiple Linux and Windows
                    server environments
                  </li>
                  <li>
                    Troubleshoot Veritas NetBackup errors and perform critical
                    data restoration
                  </li>
                  <li>
                    Collaborate on infrastructure deployments and technical
                    documentation
                  </li>
                </ul>
              </div>

              {/* Steel River Systems Experience */}
              <div className="mb-4">
                <div className="flex justify-between items-center">
                  <h3 className="font-bold text-lg">IT Support Technician</h3>
                  <p className="text-gray-600 text-sm">Aug 2020 - Sep 2023</p>
                </div>
                <p className="text-gray-700 font-semibold italic">
                  Steel River Systems, Rock Falls IL
                </p>
                <ul className="list-disc list-inside text-sm text-gray-700 space-y-1">
                  <li>
                    Provisioned and coordinated daily shipments and returns of
                    laptops and equipment for 50+ remote employees
                  </li>
                  <li>
                    Provided comprehensive end-user support for VOIP systems,
                    remote desktop troubleshooting, headset configurations, and
                    diverse hardware issues
                  </li>
                  <li>
                    Developed custom web solutions using WordPress, PHP, and
                    self-hosted systems, including a company public website with
                    personalized payment portal
                  </li>

                  <li>
                    Designed a custom intranet site with Microsoft 365 domain authentication
                  </li>
                   <li>
                    Integrated marketing and communication technologies,
                    utilizing Mautic with AWS Simple Email Service and Twilio to
                    automate customer communications
                  </li>               </ul>
              </div>

                {/* Telesales Manager Experience */}
              <div className="mb-4">
                <div className="flex justify-between items-center">
                  <h3 className="font-bold text-lg">Telesales Manager</h3>
                  <p className="text-gray-600 text-sm">Sep 2019 - Jul 2020</p>
                </div>
                <p className="text-gray-700 font-semibold italic">
                  Hayes Beer Distributing, Rockford IL
                </p>
                <ul className="list-disc list-inside text-sm text-gray-700 space-y-1">
                  <li>
                    Promoted within 5 months from Customer Sales Manager to Telesales Manager
                  </li>
                  <li>
                    Transitioned 400+ small-volume customers to telephone ordering
                  </li>
                  <li>
                    Oversaw day-to-day telesales operations
                  </li>
                </ul>
              </div>

              {/* The Rusty Fox Experience */}
              <div className="mb-4">
                <div className="flex justify-between items-center">
                  <h3 className="font-bold text-lg">Managing Partner</h3>
                  <p className="text-gray-600 text-sm">Jan 2014 - Dec 2020</p>
                </div>
                <p className="text-gray-700 font-semibold italic">
                  The Rusty Fox Wine & Alehouse, Sterling IL
                </p>
                <ul className="list-disc list-inside text-sm text-gray-700 space-y-1">
                  <li>
                    Managed all business operations, including
                    inventory management, staff coordination, bar operations, beverage and food service
                  </li>
                  <li>
                    Created weekly trivia event with original multimedia Google
                    Slides presentations, driving customer engagement and repeat
                    business
                  </li>
                  <li>
                    Developed and executed targeted marketing campaigns for
                    events and promotions, increasing venue visibility and
                    customer attraction
                  </li>
                </ul>
              </div>

              {/* Air Play / FADE Gear Experience */}
              <div className="mb-4">
                <div className="flex justify-between items-center">
                  <h3 className="font-bold text-lg">
                    Partner
                  </h3>
                  <p className="text-gray-600 text-sm">2010 - 2020</p>
                </div>
                <p className="text-gray-700 font-semibold italic">
                  Air Play Espresso & FADE Gear, Sterling IL
                </p>
                <ul className="list-disc list-inside text-sm text-gray-700 space-y-1">
                  <li>
                    Managed technical infrastructure for family-owned businesses
                  </li>
                  <li>
                    Implemented VoIP service using local PBX device to replace
                    traditional phones
                  </li>
                  <li>
                    Handled order receipt and fulfillment for wholesale
                    disc-golf business
                  </li>
                  <li>
                    Designed and maintained websites, managed marketing, and
                    customer service
                  </li>
                </ul>
              </div>

              {/* Network Operations Roles */}
              <div className="mb-4">
                <div className="flex justify-between items-center">
                  <h3 className="font-bold text-lg">
                    Network Operations Center Technician
                  </h3>
                  <p className="text-gray-600 text-sm">Jan 2002 - Aug 2010</p>
                </div>
                <p className="text-gray-700 font-semibold italic">
                  Lightedge Solutions (Moline, IL) & Avalon Networks (Iowa City,
                  IA)
                </p>
                <ul className="list-disc list-inside text-sm text-gray-700 space-y-1">
                  <li>
                    Provided technical assistance for email, web hosting, and
                    internet services
                  </li>
                  <li>
                    Worked with VoIP technologies and communication systems
                  </li>
                  <li>
                    Managed VPN and LAN/WiFi setups for business and
                    residential clients
                  </li>
                  <li>
                    Tested and repaired DSL and T1 lines via remote and on-site
                    troubleshooting
                  </li>
                </ul>
              </div>
            </div>

            {/* Skills Column */}
            <div className="pl-4 pr-4 border-solid border-gray-300 border-spacing-0 border-l">
              <h2 className="text-2xl font-semibold border-b-2 border-gray-300 mb-4">
                Technical Skills
              </h2>

              <div className="mb-4">
                <h3 className="font-bold">Programming & Development</h3>
                <p className="text-sm text-gray-600">
                  <span className="font-semibold">Languages:</span> Python,
                  JavaScript, PHP, VB, SQL, Bash
                </p>
                <p className="text-sm text-gray-600">
                  <span className="font-semibold">Web Technologies:</span>{" "}
                  React, Node.js, WordPress, HTML5, CSS3, Tailwind CSS,
                  MediaWiki
                </p>
              </div>

              <div className="mb-4">
                <h3 className="font-bold">Systems Administration</h3>
                <p className="text-sm text-gray-600">
                  <span className="font-semibold">Operating Systems:</span>{" "}
                  Linux (Ubuntu/Debian), Windows Server, ChromeOS
                </p>
                <p className="text-sm text-gray-600">
                  <span className="font-semibold">Virtualization:</span> VMware
                  Horizons, Docker
                </p>
              </div>

              <div className="mb-4">
                <h3 className="font-bold">Cloud Platforms</h3>
                <p className="text-sm text-gray-600">
                  <span className="font-semibold">Cloud Services:</span> AWS
                  (EC2, S3, SES), Microsoft Azure, Google Cloud Platform,
                  Netlify
                </p>
                <p className="text-sm text-gray-600">
                  <span className="font-semibold">
                    Monitoring & DevOps Tools:
                  </span>{" "}
                  ServiceNow, BigPanda, Netdata
                </p>
              </div>
              <div className="mb-4">
                <h3 className="font-bold">Networking & IT Infrastructure</h3>
                <p className="text-sm text-gray-600">
                  <span className="font-semibold">Networking:</span> TCP/IP,
                  VPN, LAN/WAN, VLAN Configuration, Firewall Management
                </p>
                <p className="text-sm text-gray-600">
                  <span className="font-semibold">VoIP Systems:</span> 3CX,
                  Twilio
                </p>
              </div>

              <div className="mb-4">
                <h3 className="font-bold">Business & Creative Tools</h3>
                <p className="text-sm text-gray-600">
                  <span className="font-semibold">Platforms:</span> Mautic,
                  HubSpot, Facebook Marketing
                </p>
                <p className="text-sm text-gray-600">
                  <span className="font-semibold">Point of Sale Systems:</span>{" "}
                  QuickBooks POS, Square
                </p>
                <p className="text-sm text-gray-600">
                  <span className="font-semibold">Creative Tools:</span> Adobe
                  Photoshop, Illustrator, Canva, Google Slides
                </p>
              </div>

              <div className="mb-4">
                <h2 className="text-2xl font-semibold border-b-2 border-gray-300 mb-4">
                  Education
                </h2>
                <div>
                  <h3 className="font-bold">Associates Degree</h3>
                  <p className="text-sm font-semibold text-gray-600">
                    Speech / Communications
                  </p>
                  <p className="text-gray-600 text-sm">
                    Sauk Valley Community College, 2001
                  </p>
                </div>
              </div>
              <div className="">
                <h2 className="text-2xl font-semibold border-b-2 border-gray-300 mb-4">
                  Additional Roles
                </h2>
                <div>
                  <h3 className="font-bold">
                    Sterling Industrial Development Commission
                  </h3>
                  <p className="text-gray-600 font-semibold text-sm">
                    Officer (Business Representative)
                  </p>
                  <p className="text-gray-600 text-sm">Since 2014</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Button className="inline-flex items-center gap-2 rounded-md bg-gray-700 py-1.5 px-3 text-sm/6 font-semibold text-white shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-gray-600 data-[open]:bg-gray-700 data-[focus]:outline-1 data-[focus]:outline-white" onClick={handlePrint}>Print</Button>
    </div>
  );
};

export default Resume;
